	/*
  	Flaticon icon font: Flaticon
  	Creation date: 20/09/2016 17:50
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../../fonts/Flaticon.eot");
  src: url("../../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../../fonts/Flaticon.woff") format("woff"),
       url("../../fonts/Flaticon.ttf") format("truetype"),
       url("../../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
font-family: Flaticon;
font-style: normal;
}


.flaticon-amazon-logo:before { content: "\f100"; }
.flaticon-ambulance:before { content: "\f101"; }
.flaticon-android:before { content: "\f102"; }
.flaticon-apple:before { content: "\f103"; }
.flaticon-bathtub:before { content: "\f104"; }
.flaticon-binoculars:before { content: "\f105"; }
.flaticon-briefcase:before { content: "\f106"; }
.flaticon-calendar:before { content: "\f107"; }
.flaticon-cer-file-format:before { content: "\f108"; }
.flaticon-cloud-computing:before { content: "\f109"; }
.flaticon-cloud-computing-1:before { content: "\f10a"; }
.flaticon-coding:before { content: "\f10b"; }
.flaticon-coupon:before { content: "\f10c"; }
.flaticon-cup:before { content: "\f10d"; }
.flaticon-customer-service:before { content: "\f10e"; }
.flaticon-devices:before { content: "\f10f"; }
.flaticon-domain-registration:before { content: "\f110"; }
.flaticon-download:before { content: "\f111"; }
.flaticon-family-room:before { content: "\f112"; }
.flaticon-first-aid-kit:before { content: "\f113"; }
.flaticon-folder:before { content: "\f114"; }
.flaticon-full-bed:before { content: "\f115"; }
.flaticon-github:before { content: "\f116"; }
.flaticon-happy:before { content: "\f117"; }
.flaticon-hosting:before { content: "\f118"; }
.flaticon-house:before { content: "\f119"; }
.flaticon-idea:before { content: "\f11a"; }
.flaticon-lifesaver-security-sportive-tool:before { content: "\f11b"; }
.flaticon-line-graph:before { content: "\f11c"; }
.flaticon-list:before { content: "\f11d"; }
.flaticon-locked:before { content: "\f11e"; }
.flaticon-mail:before { content: "\f11f"; }
.flaticon-money:before { content: "\f120"; }
.flaticon-new-file:before { content: "\f121"; }
.flaticon-padlock:before { content: "\f122"; }
.flaticon-pantone:before { content: "\f123"; }
.flaticon-play-button:before { content: "\f124"; }
.flaticon-price-tag:before { content: "\f125"; }
.flaticon-school-bus:before { content: "\f126"; }
.flaticon-search:before { content: "\f127"; }
.flaticon-server:before { content: "\f128"; }
.flaticon-stopwatch:before { content: "\f129"; }
.flaticon-support:before { content: "\f12a"; }
.flaticon-swimming-pool:before { content: "\f12b"; }
.flaticon-unlink:before { content: "\f12c"; }
.flaticon-windows:before { content: "\f12d"; }
.flaticon-wordpress-logo:before { content: "\f12e"; }
.flaticon-world-wide-web:before { content: "\f12f"; }